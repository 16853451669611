import { Component, Input, EventEmitter, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { PageOption } from 'src/app/models';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges {
    @Input() totalRecords = 0;
    @Input() recordsPerPage = 0;

    public pages: number[] = [];
    activePage = 1;

    private pageChangeSubject = new BehaviorSubject<number>(this.activePage);
    public pageChange$ = this.pageChangeSubject.asObservable();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnChanges(): void {
        const isInitializing = this.activePage == null;
        const pageCount = this.getPageCount();
        this.pages = this.getArrayOfPage(pageCount);
        this.changeDetectorRef.detectChanges();
    }

    private getPageCount(): number {
        let totalPage = 0;

        if (this.totalRecords > 0 && this.recordsPerPage > 0) {
            const pageCount = this.totalRecords / this.recordsPerPage;
            const roundedPageCount = Math.floor(pageCount);

            totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
        }

        return totalPage;
    }

    private getArrayOfPage(pageCount: number): number[] {
        const pageArray: number[] = [];

        if (pageCount > 0) {
            for (let i = 1; i <= pageCount; i++) {
                pageArray.push(i);
            }
        }

        return pageArray;
    }

    getPageOption(): PageOption {
        return {
            skip: (this.activePage - 1) * this.recordsPerPage,
            take: this.recordsPerPage
        };
    }

    next(): void {
        this.onClickPage(this.activePage + 1);
    }

    previous(): void {
        this.onClickPage(this.activePage - 1);
    }

    gotoFirstPage(): void {
        this.activePage = 1;
        this.pageChangeSubject.next(this.activePage);
    }

    onClickPage(pageNumber: number): void {
        if (pageNumber < 1) {
            return;
        }
        if (pageNumber > this.pages.length) {
            return;
        }
        this.activePage = pageNumber;
        this.pageChangeSubject.next(this.activePage);
    }
}
