import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { AuthorizationService } from "./authorization.service";

@Injectable()
export class JWTTokenHttpInterceptor implements HttpInterceptor {

    constructor(
        private authorizationService: AuthorizationService,
        private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let accessToken = this.authorizationService.getAuthToken();
        if (accessToken) {
            request = request.clone({ headers: request.headers.set('Authorization', accessToken) })
        }
        return next.handle(request).pipe(
            catchError((err: any) => {
                if (err.status == 401) {
                    this.silentLogout();
                }
                return throwError(() => err);
            })
        );
    }

    silentLogout(): void {
        this.authorizationService.logOut();
        this.router.navigate(['/account/login']);
    }

}