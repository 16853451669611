import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, TemplateRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, combineLatest, debounceTime, delay, fromEvent, Observable, startWith, Subject, Subscription, switchMap, tap } from "rxjs";

@Component({
    selector: 'app-searchable-dropdown',
    templateUrl: './searchable-dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchableDropdownComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableDropdownComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @ViewChild('searchText') searchTextBox!: ElementRef;
    @ViewChild('dropdown') dropdownButton!: ElementRef;
    @ContentChild('optionTemplate') optionTemplateRef!: TemplateRef<any>;
    @ContentChild('selectedTemplate') selectedTemplateRef!: TemplateRef<any>;

    public get control(): SearchableDropdownComponent {
        return this;
    }

    @Input()
    optionTextPath!: string;

    @Input()
    optionValuePath!: string;

    @Input()
    fetchUsersFn!: (searchStr: string) => Observable<any[]>


    @Input()
    showSearchBox = false;

    @Input()
    readonly = false;

    onChange = (arg: any) => { };
    onTouched = () => { };
    selectedValue: any;
    selectedItem: any;
    searchText: string | undefined;

    public searchTextBoxTyped = new Subject<KeyboardEvent>();
    subscription = new Subscription();

    public searchTextChangedSubject = new Subject<string>();
    public searchTextChanged$ = this.searchTextChangedSubject.asObservable();

    public options: any[] = [];
    public options$ = this.searchTextChanged$.pipe(
        debounceTime(200),
        switchMap(x => this.fetchUsersFn(x))
    ).subscribe((res) => { this.options = res })

    updateValue(selectedItem: any): void {
        if (selectedItem == null) {
            this.selectedValue = null;
            this.selectedItem = null;
            this.onChange(selectedItem);
            return;
        }
        if (this.optionValuePath) {
            this.onChange(selectedItem[this.optionValuePath]);
            this.selectedValue = selectedItem[this.optionValuePath];
            this.selectedItem = selectedItem;
            return;
        }
        this.selectedValue = selectedItem[this.optionValuePath];
        this.selectedItem = selectedItem;
        this.onChange(selectedItem);
    }

    ngAfterViewInit(): void {
        this.subscription.add(
            this.searchTextBoxTyped.pipe(startWith(0)).pipe(
                delay(0),
                tap((event) => {
                    if (!event || (event as any).target == null) {
                        this.searchTextChangedSubject.next('');
                        return;
                    }
                    this.searchTextChangedSubject.next((event as any).target.value);
                })
            ).subscribe());

        this.subscription.add(fromEvent(this.dropdownButton.nativeElement, 'blur').pipe(
            tap((args: any) => {
                this.onTouched();
                // $(args.target.parentElement.parentElement).trigger('focusout');
            })
        ).subscribe());
    }

    isSelected(option: any): boolean {
        if (!this.selectedValue) {
            return false;
        }
        if (this.optionValuePath) {
            return this.selectedValue === option[this.optionValuePath];
        } else {
            return this.selectedValue === option;
        }
    }

    writeValue(value: any): void {
        this.selectedValue = value;
        this.setSelectedItem();
    }

    private setSelectedItem(): void {
        if (this.optionValuePath) {
            this.selectedItem = this.options.find(o => o[this.optionValuePath] === this.selectedValue);
        } else {
            this.selectedItem = this.options.find(o => o == this.selectedValue);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}