import { Injectable } from "@angular/core";
import { LoginResponse } from "../models";
import { Privilege } from "../models/enum";

@Injectable()
export class AuthorizationService {
    private static loginResponseKey = 'loginResponse';

    public authInfo: LoginResponse | null = null;

    setLoginResponse(loginResponse: LoginResponse): void {
        this.authInfo = loginResponse;
        localStorage.setItem(AuthorizationService.loginResponseKey, JSON.stringify(loginResponse));
    }

    updateAuth(loginResponse: LoginResponse): void {
        this.setLoginResponse({
            ...this.authInfo,
            ...loginResponse
        });
    }

    getAuthToken(): string {
        if (this.hasAuthenticated) {
            return 'Bearer ' + this.authInfo?.token;
        }
        return "";
    }

    getLoginToken(): string | null {
        if (this.hasValidLoginToken) {
            return 'Bearer ' + this.loginResponse?.token;
        }
        return null;
    }

    public get loginResponse(): LoginResponse | null {
        let auth = localStorage.getItem(AuthorizationService.loginResponseKey)
        if (auth != null) {
            return JSON.parse(auth) as LoginResponse;
        }
        return null;
    }

    public get organisationId(): number  | undefined | null {
        return this.loginResponse?.organisationId;
    }

    public get roleId(): number | undefined | null {
        return this.loginResponse?.roleId;
    }

    get hasValidLoginToken(): boolean {
        const loginInfo = this.loginResponse;
        if (loginInfo == null) {
            return false;
        }
        if (new Date() > new Date(loginInfo.tokenExpiryOn)) {
            return false;
        }
        return true;
    }

    private get hasAuthenticated(): boolean {
        if (this.authInfo == null) {
            var auth = localStorage.getItem(AuthorizationService.loginResponseKey);
            if (auth) {
                this.authInfo = JSON.parse(auth);
            }
        }
        return this.authInfo != null;
    }

    get hasExpiredToken(): boolean {
        return new Date() > new Date(this.authInfo!.tokenExpiryOn);
    }

    get hasValidToken(): boolean {
        return this.hasAuthenticated && !this.hasExpiredToken;
    }

    public hasPrivilegeOf(privilege: Privilege) {
        return this.loginResponse?.privileges?.some(p => p == privilege) ?? false;
    }

    logOut(): void {
        localStorage.removeItem(AuthorizationService.loginResponseKey);
        this.authInfo = null;
    }
}
