import { OtpMode, Privilege } from "./enum";

export class LoginRequest {
    userName!: string;
    password!: string;
}

export class LoginResponse {
    roleId!: number;
    firstName?: string;
    lastName?: string;
    email: string = "";
    token: string = "";
    tokenExpiryOn: Date = new Date();
    privileges: Privilege[] = [];
    organisationId!: number;
}

export class SetPasswordRequest {
    token!: string;
    newPassword!: string;
    confirmPassword!: string;
}

export class InvitationRequest {
    token!: string;
}

export class OtpRequest {
    mode!: OtpMode;
    email?: string | null;
    phone?: string | null;  
}

export class ValidateOtpRequest {
    otp!: string;
    email?: string | null;
    phone?: string | null;
}
