import { Component } from '@angular/core';
import { SpinnerConstants } from './models';
import { MyMonitoringService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DonationWebApp';
  appSpinner = SpinnerConstants.App;
  constructor(private myMonitoringService: MyMonitoringService) {
    
  }
}
