import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpService {
    constructor(private http: HttpClient) {
    }

    public get<T>(url: string, headers?: any): Observable<T> {
        return this.http.get<T>(url , {headers});
    }

    public post<T>(url: string, item: any, headers?: any): Observable<T> {

        const toAdd = JSON.stringify(item);
        return this.http.post<T>(url, toAdd, {headers});
    }

    public update<T>(url: string, itemToUpdate: any): Observable<T> {
        return this.http
            .put<T>(url, JSON.stringify(itemToUpdate));
    }

    public delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(url);
    }

    public postFile<T>(url: string, item: any): Observable<T> {
        return this.http.post<T>(url, item);
    }
}
