import { Component, Input } from "@angular/core";
import { NotOkBaseClass } from "src/app/models/error.model";

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {
    @Input()
    errorModel: NotOkBaseClass | null = null;
}