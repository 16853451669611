import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
    @Input()
    name!: string;

    @Input()
    fullScreen = false;

    @Input()
    message = 'Loading...';

    @Input()
    bdColor = 'rgba(0, 0, 0, 0.8)';

    @Input()
    color = '#A865C9';//'#3a43c0';
}
