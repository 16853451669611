import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { OrganizationModel } from "src/app/models/organization.model";
import { BaseService } from "src/app/services/base.service";
import { HttpService } from "src/app/services/http.service";

@Injectable()
export class OrganizationSettingsService extends BaseService{
    constructor(private httpService: HttpService) {
        super();
    }

    getSettings(): Observable<OrganizationModel> {
        return this.httpService.get<OrganizationModel>(this.baseUrl + `/organizationSettings`)
        .pipe(catchError(this.errorHandler));
    }

    saveSettings(organization: OrganizationModel): Observable<OrganizationModel> {
        return this.httpService.post<OrganizationModel>(this.baseUrl + '/organizationSettings', organization)
        .pipe(catchError(this.errorHandler));
    }
}