import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { ApiConfigService } from "./api-config.service";

@Injectable()
export class BaseService {
  protected baseUrl: string;
  constructor() {
    this.baseUrl = ApiConfigService.getServiceURL();
  }

  errorHandler = <T>(error: HttpErrorResponse, caught?: Observable<T>): Observable<T> => {
    if (error.status === 400 && error.hasOwnProperty('error')) {
      // error = error.error;
      // for (const errorKey in error) {
      //   if (error.hasOwnProperty(errorKey)) {
      //     const errors = error[errorKey];
      //     for (const errorMessage of errors) {
      //       alert(errorMessage);
      //     }
      //   }
      // }

    } else if ((error.status === 401 || error.status === 404 ||
      error.status === 410 || error.status === 412 || error.status === 409) && error.error != null) {
      return throwError(() => ({
        ...error.error,
        statusCode: error.status
      }));
    } else if (error.status === 500 && error.error != null) {
      return throwError(() =>  ({
        error: 'Server Error',
        statusCode: error.status
      }));
    } else if (error.status === 403) {
      return throwError(() => ({
        error: 'Not authorized!',
        statusCode: error.status
      }))
    } 
    else if (error.status === 0) {
      return throwError(() => ({
        error: 'Network issue!',
        statusCode: error.status
      }));
    }
    return throwError(() => error);
  }
}
