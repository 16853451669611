<div class="btn-block flex-column flex-md-row d-flex justify-content-md-center align-items-md-center">
    <nav aria-label="..." class="overflow-auto">
        <ul class="pagination">
            <li class="page-item" (click)="previous()">
                <span class="page-link"><i class="fa-solid fa-angle-left" aria-hidden="true"></i></span>
            </li>
            <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"
            (click)="onClickPage(item)">
                <span class="page-link">{{item}}<span class="sr-only"></span></span>
            </li>
            <li class="page-item" (click)="next()">
                <a class="page-link"><i class="fa-solid fa-angle-right" aria-hidden="true"></i></a>
            </li>
        </ul>
    </nav>
</div>