import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { ErrorMessageComponent } from "./components/error-message/error-message.component";
import { PrivilegeGuard } from "./services/privilege.guard";
import { AuthorizationGuard } from "./services/authorization.guard";
import { BrowserModule } from "@angular/platform-browser";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { LogoUploaderComponent } from "./components/logo-uploader/logo-uploader.component";
import { OtpDigitDirective } from "./directives/otp-digit.directive";
import { SearchableDropdownComponent } from "./components/searchable-dropdown/searchable-dropdown.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        NgbDropdownModule
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorMessageComponent,
        PaginationComponent,
        SpinnerComponent,
        LogoUploaderComponent,
        OtpDigitDirective,
        SearchableDropdownComponent,
        FileUploaderComponent
    ],
    declarations: [
        ErrorMessageComponent,
        PaginationComponent,
        SpinnerComponent,
        LogoUploaderComponent,
        OtpDigitDirective,
        SearchableDropdownComponent,
        FileUploaderComponent
    ],
    providers: [
        PrivilegeGuard,
        AuthorizationGuard
    ]
})
export class SharedModule {

}