
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthorizationService } from 'src/app/services';

@Injectable()
export class AuthorizationGuard  {
    constructor(private authorizationService: AuthorizationService, private router: Router) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authorizationService.hasValidLoginToken) {
            return true;
        }
        this.router.navigate(['/account/login']);
        return false;
    }
}
