import { Component, Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: '[otpDigit]'
})
export class OtpDigitDirective {
    private el: ElementRef;
    @Input()
    otpDigit?: string;

    constructor(private _el: ElementRef) {
        this.el = this._el;
    }

    static allowedKeyCodes = [9, 16];

    @HostListener('keyup', ['$event']) onKeyDown(e: any) {
        if (e.keyCode == 8 ) {
            if (this.previousElement(e?.srcElement)) {
                this.previousElement(e?.srcElement).focus();
            }
            return;    
        }
        if (OtpDigitDirective.allowedKeyCodes.some((kc) => e.keyCode == kc)) {
            return;
        }
        if (this.nextElement(e?.srcElement)) {
            this.nextElement(e?.srcElement).focus();
        }
        return;
    }

    nextElement = (element: any): any => {
        if (element?.nextElementSibling?.nodeName == 'INPUT') {
            return element?.nextElementSibling
        } else if (element?.nextElementSibling != null) {
            return this.nextElement(element?.nextElementSibling);
        }
        return null;
    }

    previousElement = (element: any): any => {
        if (element?.previousElementSibling?.nodeName == 'INPUT') {
            return element?.previousElementSibling
        } else if (element?.previousElementSibling != null) {
            return this.previousElement(element?.previousElementSibling);
        }
        return null;
    }
}