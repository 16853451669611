import { Injectable } from '@angular/core';
import { BaseService } from "src/app/services/base.service";
import { HttpService } from "src/app/services/http.service";
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FileUploadService extends BaseService {
    constructor(private httpService: HttpService) {
        super();
    }

    upload(folderName: string, formData: FormData): Observable<string> {
        return this.httpService.postFile<string>(`${this.baseUrl}/Upload/Blob/${folderName}`, formData)
            .pipe(catchError(this.errorHandler));
    }
}
