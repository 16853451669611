import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { OrganizationFilter, OrganizationModel, OrganizationResponse } from "src/app/models/organization.model";
import { BaseService } from "src/app/services/base.service";
import { HttpService } from "src/app/services/http.service";

@Injectable()
export class OrganizationService extends BaseService {
    constructor(private httpService: HttpService) {
        super();
    }

    organizations(organizationFilter: OrganizationFilter): Observable<OrganizationResponse> {
        return this.httpService.get<OrganizationResponse>(this.baseUrl + `/organizations?SearchText=${organizationFilter.name}&Skip=${organizationFilter.skip ?? 0}&Take=${organizationFilter.take ?? 0}`)
        .pipe(catchError(this.errorHandler));
    }

    organization(id: number): Observable<OrganizationModel> {
        return this.httpService.get<OrganizationModel>(this.baseUrl + `/organizations/${id}`)
        .pipe(catchError(this.errorHandler));
    }

    save(organization: OrganizationModel): Observable<OrganizationModel> {
        return this.httpService.post<OrganizationModel>(this.baseUrl + '/organizations', organization)
        .pipe(catchError(this.errorHandler));
    }
}