
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthorizationService } from 'src/app/services';

@Injectable()
export class PrivilegeGuard  {
    constructor(
        private authorizationService: AuthorizationService,
        private router: Router) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const requiredPrivileges = next.data['requiredPrivileges'];
        if (requiredPrivileges && requiredPrivileges.length) {
            const authorizedPrivileges = this.authorizationService.loginResponse?.privileges ?? [];
            let isAuthorized = true;
            for (const requiredPrivilege of requiredPrivileges) {
                if (!authorizedPrivileges.some(ap => ap === requiredPrivilege)) {
                    isAuthorized = false;
                    break;
                }
            }
            if (!isAuthorized) {
                this.router.navigate(['/account/login']);
            }
            return isAuthorized;
        }
        return true;
    }
}
