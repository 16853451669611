<div ngbDropdown class="d-inline-block">
    <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle #dropdown
        [ngClass]="{'disabled': readonly}">
        <ng-template [ngTemplateOutlet]="selectedTemplateRef" [ngTemplateOutletContext]="{$implicit: (selectedItem)}">
        </ng-template>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <input class="form-control live-search-box" aria-haspopup="true" aria-expanded="false" placeholder="Search"
            (keyup)="searchTextBoxTyped.next($event)">
            <ng-content select="[please-select]"></ng-content>
            <ng-content select="[add-new]"></ng-content>
        <a ngbDropdownItem *ngFor="let option of options" [ngClass]="{'active': isSelected(option)}"
            (click)="updateValue(option)">
            <ng-template [ngTemplateOutlet]="optionTemplateRef" [ngTemplateOutletContext]="{$implicit: (option)}">
            </ng-template>
        </a>
    </div>
</div>
