import { Component, EventEmitter, Output, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-logo-uploader',
    templateUrl: './logo-uploader.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LogoUploaderComponent),
            multi: true
        }
    ]
})
export class LogoUploaderComponent implements ControlValueAccessor {
    @Input()
    uploadFileFn!: (formData: FormData) => Observable<string>;

    onChange: any;
    valueSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    value$: Observable<string> = this.valueSubject.asObservable();

    upload(event: any): void {
        const input: HTMLInputElement = event.srcElement;
        if (input.files && input.files[0]) {
            const formData = new FormData();
            formData.append('file', input.files[0], input.files[0].name);
            if (this.uploadFileFn) {
                this.uploadFileFn(formData).subscribe(path => {
                    this.onChange(path);
                    this.writeValue(path);
                });
            }
        }
    }

    writeValue(val: string): void {
        this.valueSubject.next(val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }
}
