import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationService } from './services';
import { ApiConfigService } from './services/api-config.service';
import { ContentTypeHttpInterceptor } from './services/content-type.http.interceptor.service';
import { HttpService } from './services/http.service';
import { JWTTokenHttpInterceptor } from './services/jwt-token.http.interceptor.service';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrganizationService } from './layout/organization/services/organization.service';
import { FileUploadService } from './layout/organization/services/file-upload.service';
import { OrganizationSettingsService } from './layout/services/organization-settings.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { MyMonitoringService } from './services/logging.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTTokenHttpInterceptor,
      multi: true,
    },
    AuthorizationService,
    ApiConfigService,
    HttpService,
    OrganizationService,
    FileUploadService,
    OrganizationSettingsService,
    MyMonitoringService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
