export class SpinnerConstants {
    public static readonly App = 'App';
    public static readonly OrganizationList = 'OrganizationList';
    public static readonly OrganizationForm = 'OrganizationForm';
    public static readonly DonorList = 'DonorList';
    public static readonly DonorForm = 'DonorForm';
    public static readonly ManualDeposit = 'ManualDeposit';
    public static readonly TransactionList = 'TransactionList';
    public static readonly AdminTaxList = 'AdminTaxList';
    public static readonly DonorTaxList = 'DonorTaxList';
    public static readonly BillingAddressSpinner = 'BillingAddressSpinner';
    public static readonly RecurringPaymentList = 'RecurringPaymentList';
    public static readonly RecurringPaymentForm = 'RecurringPaymentForm';
    public static readonly CommunicationForm = 'CommunicationForm';
    public static readonly PaymentMethodsList = 'PaymentMethodsList';
}

export class PaginationConstants {
    public static readonly RecordsPerPage = 10;
}

export class BlobConstants {
    public static readonly OrganizationsContainerName = "Organizations";
    public static readonly TaxLettersContainerName = "Organizations/TaxLetters";
}